import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BannerDiv = styled("div")`
  margin-bottom: 30px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
  border-style: solid;
  border-width: 3px;
  padding: 2% 5% 2% 5%;
`
const SqBtn = styled("div")`
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0;
  text-decoration: none;
  font-weight: 500;
  padding: 0.3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  position: relative;
  transition: all 300ms ease 0ms;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  :hover {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
`
const ThePage = () => {
  const data = useStaticQuery(graphql`
    query vtech3Query {
      top: file(relativePath: { eq: "PIE_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bleft: file(relativePath: { eq: "PIE_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bright: file(relativePath: { eq: "PIE_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="抗疫四式 第三式《放！》 PIE 臭氧消毒裝置 ｜櫻花家居服務"
        description="香港科學園研發 PIE 臭氧消毒裝置，臭氧殺菌能力快捷強勁。用氯殺菌所需時間爲 1 小時，但用臭氧只需約 1 秒鐘⚡能在1秒內有效殺死細菌🧫、真菌🍄及冠狀病毒🦠 臭氧是以氧化作用破壞微生物膜的結構💥🧬 能與空氣結合，迅速瀰漫到整個空間進行滅菌⚠️"
      />
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                    color: #4691f8;
                  `}
                >
                  第三式《放！》 PIE 臭氧消毒裝置
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <BannerDiv
                css={css`
                  border-color: #4691f8;
                  background-color: rgba(70, 145, 248, 0.25);
                `}
              >
                <p>
                  <Img fluid={data.top.childImageSharp.fluid} />
                </p>
                <p>香港科學園研發 PIE 臭氧消毒裝置，臭氧殺菌能力快捷強勁。</p>

                <p>
                  《PIE
                  臭氧消毒裝置》針對已確診或懷疑確診個案，我地推介使用PIE臭氧機，可在室內空間使用：家居🏠、辦公室🏢、學校🏫等！
                  <br />
                  臭氧殺菌能力快捷強勁，只需約 1
                  秒鐘⚡能在1秒內有效殺死細菌、真菌及冠狀病毒！
                  <br />
                  – 臭氧是以氧化作用破壞微生物膜的結構💥🧬
                  <br />
                  – 能與空氣結合，迅速瀰漫到整個空間進行滅菌⚠️
                  <br />
                  – 可持續進行室內空氣及物件表面消毒🌿除菌除臭
                  <br />
                  – 消毒後不會殘留任何有害物質💨
                  <br />
                  – 不需噴灑塗層！一機搞掂🎛！
                  <br />
                  PIE 為香港唯一的工業級臭氧機生產商
                  <br />
                  ✅03年起已成功研製和供應工業級之臭氧機和技術予不同領域✨
                  <br />
                  ✅現時選用PIE的大型客戶：特區政府部門、港鐵、迪士尼樂園、國際金融中心等，信心保證❤
                  <br />
                </p>
              </BannerDiv>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <p>
                <Img fluid={data.bleft.childImageSharp.fluid} />
              </p>
            </Col>
            <Col md={6} xs={12}>
              <p>
                <Img fluid={data.bright.childImageSharp.fluid} />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Link to="/抗疫四式第一式-relyon">
                <SqBtn
                  css={css`
                    color: #fc9201 !important;
                    border-color: #fc9201;
                  `}
                >
                  第一式《噴！》RelyOn Virkon 殺菌劑🇺🇸
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第二式-hoci">
                <SqBtn
                  css={css`
                    color: #f900de !important;
                    border-color: #f900de;
                  `}
                >
                  第二式《抹！》 HOCI 次亜塩素酸🇯🇵
                </SqBtn>
              </Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to="/櫻花消毒技術">
                <SqBtn
                  css={css`
                    color: #4691f8 !important;
                    border-color: #4691f8;
                  `}
                >
                  返回「櫻花消毒技術」
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第四式-日本東芝光觸媒">
                <SqBtn
                  css={css`
                    color: #01dcbc !important;
                    border-color: #01dcbc;
                  `}
                >
                  第四式《抗！》日本東芝光觸媒
                </SqBtn>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/booking" className="button">
                  <b>立即預約</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/消毒案例" className="button">
                  <b>消毒案例</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
